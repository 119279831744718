import logo from './logo.svg';

import React, {useEffect, useState} from 'react';
import {
	HashRouter as Router,
	Routes,
	Route,
	Link,
	Redirect,
	useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import './Style.scss';
import { createHashHistory } from 'history'
import { useSelector, useDispatch } from 'react-redux';
import { getId, reset, showDlg, setDialog, setId } from './utils/Redux';
import {query, MySelect} from './utils/utils';

import Confirm from './pages/Confirm';
import HowTo from './pages/HowTo';
import Impressum from './pages/Impressum';
import Login from './pages/Login';
import MyRatings from './pages/MyRatings';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Reset from './pages/Reset'
import Search from './pages/Search'
import Terms from './pages/Terms';


function App(props) {
	const dispatch = useDispatch();
	const user = useSelector(getId);
	const [msg, setMsg] = useState(0);
	const [name, setName] = useState(null);

	const showDialog = useSelector(showDlg);
	const history = props.history;

	const styles = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
		fontFamily: "Arial"
	}
	const dlgStyles = {
		position:"absolute",
		top:0,
		left:0,
		width:"100%",
		height:"100%",
		backgroundColor: "#0008",
		justifyContent: "center",
		alignItems: "center",
		display: showDialog ? "flex" : "none"
	};



	useEffect(() => {
		document.title = "Boardgame Predictor";
		dispatch(setDialog(false));
		// dispatch(reset(null));
		query('check', {}, res => {
			console.log("res", res)
			if(user != res.id) dispatch(setId(res.id));
			setName(res.name);
		}, () => dispatch(reset(null)))
	},[])


	var switch1 = (
		<Routes>
			<Route key="impressum" path="/impressum" element={<Impressum />}/>
			<Route key="Terms" path="/Terms" element={<Terms />}/>
			<Route path="/register" element={<Register />}/>
			<Route path="/reset/:id/:key" element={<Reset/>}/>
			<Route path="/confirm/:id/:key" element={<Confirm/>}/>
			<Route path="/" element={<Login/>}/>
		</Routes>
	)


	var switch2 = (
		<Routes>
			<Route key="impressum" path="/impressum" element={<Impressum />}/>
			<Route key="Terms" path="/Terms" element={<Terms />}/>
			<Route path="/profile" element={<Profile history={props.history}/>}/>
			<Route path="/logout" element={<Logout history={props.history}/>}/>
			<Route path="/myratings" element={<MyRatings/>}/>
			<Route path="/" element={<Search/>}/>
		</Routes>
	)

	var header1 = (
		<div id="header">
			<div id="headerLeft">
				<Link to="/"><img style={{height:"79px", marginBottom:"-11px"}} src="img/Logo.png"/></Link>
			</div>
			<div id="headerCenter">
			</div>
			<div id="headerRight"><Link to="/login">Login</Link></div>
		</div>
	)

	var header2 = (
		<div id="header">
			<div id="headerLeft">
				<Link  style={{display:"flex", alignItems:"center"}} className="btn" to="/">
					<img src="img/search.png" style={{height:"50px", marginRight:"10px"}}/>
					Search
				</Link>
				<div></div>
			</div>
			<div id="headerCenter">
				<Link to="/"><img style={{height:"79px", marginBottom:"-11px"}} src="img/Logo.png"/></Link>

			</div>
			<div id="headerRight" style={{textAlign:"right"}}>
				<Link style={{display:"flex", alignItems:"center"}} className="btn" to="/myratings">
					My Ratings
				</Link>
				{msg>0 && <div className="indicator">{msg}</div>}
				<Link style={{display:"flex", alignItems:"center"}} className="btn" to="/profile">
					<img src="img/profile.png" style={{height:"30px", marginRight:"10px"}}/>
					Profil
				</Link>
				<Link style={{display:"flex", alignItems:"center"}} className="btn" to="/logout">
					<img src="img/logout.png" style={{height:"30px", marginRight:"10px"}}/>
					Logout
				</Link>
			</div>
		</div>
	)

	return (
		<div className="App" style={styles}>
				{(user < 0) ? header1 : header2}
				<div id="main">
					{(user < 0) ? switch1 : switch2}
				</div>
				<div id="footer">
					<Link to="impressum">Impressum</Link>
						&nbsp;&nbsp;&nbsp;&nbsp;
					<Link to="Terms">AGB & Datenschutz</Link>
				</div>
				<div id="dialog" style={dlgStyles}>
					<div id="dlgContent" style={{backgroundColor:"white", display:showDialog == 2 ? 'block' : 'none' }}></div>
				</div>
		</div>
	)
}

function Logout(props) {
	const dispatch = useDispatch();
	useEffect(() => {
		query('logout', {}, () =>	{
			dispatch(reset(null))
			props.history.push("/login");
		});
	},[]);
	return null;
}


function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

export default withRouter(App);
