import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {getId, setDialog} from '../utils/Redux';
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions, serverUrl, formatDate} from '../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import {Link} from "react-router-dom";

export default function Profile(props) {
	const dispatch = useDispatch();
	const [user, setUser] = useState(null);
	const update = () => {
		query('profile', [], data => {
			console.log(data)
			setUser(data);
		})
	}
	useEffect( update,[])

	function deleteProfile() {
			dispatch(setDialog(2));
			ReactDOM.render(<DelProfile close={e => dispatch(setDialog(0))} history={props.history}/>, document.getElementById("dlgContent"))
	}

	return user && (<div id="profile">
			<UserSettings user={user}/>
			<button className="btn"  style={{fontSize:"14px", marginTop:"20px"}} onClick={e => deleteProfile()}>Profil löschen</button>
		</div>)
}

function UserSettings(props) {
	const [user, setUser] = useState(props.user);
	const [originalUser, setOriginal] = useState(props.user);
	const [err, setErr] = useState(null);


	function setUserValue(val, idx) {
		var v = (val.length == 0) ? null : val;
		setUser({...user, [idx]:v});
	}

	const dispatch = useDispatch();

	const save = () => {
		query('updateUser', user, data =>setOriginal(user), err => { // TODO:
			setErr(err);
		})
	}


	return (
		<div id="userSettings" className="container">
			<h2><span>Persönliche Infos</span> <button className="btn" onClick={save}><img src="img/check.png" style={{height:"90%", marginRight:"-5PX"}}/> speichern</button></h2>
			<div className="filler">
				{err && <p style={{color:"red", margin:"0px"}}>{err}</p> }
				<table><tbody>
					<MyTr lbl="Neues Passwort:"><MyInput type="password" original="" value={user.pw} set={setUserValue} idx="pw"/></MyTr>
					<MyTr lbl="Passwort wiederholen:"><MyInput type="password" original="" value={user.pw2} set={setUserValue} idx="pw2"/></MyTr>
					<tr><td>E-Mail Adresse:</td><td><MyInput type="text" value={user.mail} original={originalUser.mail} set={setUserValue} idx="mail"/></td></tr>
				</tbody></table>
			</div>
		</div>
	)
}

function DelProfile(props) {
	const [pw, setPW] = useState("");
	const [err, setErr] = useState(null);
	function confirm() {
		query('deleteProfile', {pw:pw}, () => {
			props.close();
			props.history.push('/logout');
		}, err => {
			setErr(err.code == 1 ? err.msg : "Unerwarteter Fehler")
		})
	}

	return (
		<div style={{display:"flex", flexDirection:"column", alignItems:"center", padding:"10px"}}>
			<div style={{textAlign:"center"}}>
				Möchtest du dein Profil wirklich löschen?<br/>
				Hierbei werden alle deine Daten gelöscht.<br/>
				Dies kann nicht rückgängig gemacht werden.<br/>
				Zur Bestätigung gib bitte dein Passwort ein:
			</div><br/>
			{err && <div style={{color:"red"}}>{err}</div>}
			<MyInput type="password" value={pw} set={setPW}/><br/>
			<div>
				<button id="uploadbtn" className="btn" type="submit" onClick={props.close}>abbrechen</button>
				&nbsp;&nbsp;&nbsp;
				<button id="deletebtn" className="btn" onClick={confirm}>Profil löschen</button>
			</div>
		</div>
	)
}
