import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {query, MySelect} from '../utils/utils';

export default function MyRatings() {

	const [ratings, setRatings] = useState([])
	const [sortMode, setSortMode] = useState(1)

	useEffect(() => {
		query('getRatings', {}, setRatings, () => {})
	},[])

	const changeRating = (idx, rating) => {
		let id = ratings[idx].id
		query('changeRating', {id, rating}, () => {
			let copy = [...ratings]
			copy[idx] = {...copy[idx], rating}
			setRatings(copy)

		})
	}
	const arr = [0,1,2,3,4,5,6,7,8,9]

	const sort = i => {
		let m = i == Math.abs(sortMode) ? -sortMode : i
		switch(m) {
			case 1: setRatings(ratings.toSorted((x,y) => x.name.localeCompare(y.name))); break;
			case -1: setRatings(ratings.toSorted((x,y) => y.name.localeCompare(x.name))); break;
			case 2: setRatings(ratings.toSorted((x,y) => x.rating-y.rating)); break;
			case -2: setRatings(ratings.toSorted((x,y) => y.rating-x.rating)); break;
		}
		setSortMode(m)
	}

	return (<div>
		<h3>My Ratings</h3>
		<table><tbody>
			<tr>
				<th style={{cursor:'pointer'}} onClick={() => sort(1)}>Name {sortMode == 1 && '▵'}{sortMode == -1 && '▿'}</th>
				<th style={{cursor:'pointer'}} onClick={() => sort(2)}>Rating {sortMode == 2 && '▵'}{sortMode == -2 && '▿'}</th>
			</tr>
			{ratings.map((row,i) => (<tr key={i}>
				<td>{row.name}</td>
				<td style={{paddingLeft:'10px'}}>
					{arr.map(j => (<div key={j} className="star" onClick={() => changeRating(i, j+1)}>
						{j < row.rating ? '★' : '☆'}
					</div>))}
				</td>
			</tr>))}
		</tbody></table>
		</div>)
}
