import {createStore} from 'redux'
import {createSlice, configureStore} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const initial = {
	id: -1,
	name: "",
	showDlg: false,
}

const slice = createSlice({
	name: 'user',
	initialState: initial,

	reducers: {
		reset: (state, action) => {
			state.id = -9
			state.name = -1
		},

		setId: (state, action) => {
			state.id = action.payload;
		},

		setDialog: (state, action) => {
			state.showDlg = action.payload;
		},

	},
});

export const getId = state => state.id;
export const showDlg = state => state.showDlg;
export const {reset, setId, setDialog} = slice.actions;

const persistConfig = {
	key: 'bgr',
	storage
}
const persistedReducer = persistReducer(persistConfig, slice.reducer)

export const store = configureStore({
	reducer: persistedReducer
});

export const persistor = persistStore(store)
