import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setId, getId } from '../utils/Redux';
import Register from './Register';
import {MyInput, MySelect, MyTextArea, MyTr, query, formatRange, createOptions} from '../utils/utils';
import HowTo from './HowTo';

export default function Login(props) {

	const [name, setName] = useState("");
	const [pw, setPw] = useState("");
	const [err, setErr] = useState(null);
	const [info, setInfo] = useState(null);
	const dispatch = useDispatch();

	function doLogin() {
		query('login',{name: name, pw:pw}, (result) => {
			switch (result.code) {
				case 0:
					setErr("Falscher Benutzername oder Passwort");
					break;
				case 1:
					setErr("Bitte bestätigen Sie zuerst Ihre EMail Adresse");
					break;
				case 2:
					dispatch(setId(result.id));
					//props.history.push("/");
					break;
				default:
					setErr("Server-Error");
					break;
			}
		});
	}

	function reset() {
		query('reset', {name: name}, result => {
			setInfo('Eine E-Mail um Ihr Passwort zu ändern wurde an Ihre E-Mail Adresse geschickt')
		}, err => {
			if(err == 102) setErr("Benutzer nicht gefunden");
			else if(err == 103) setErr("Bitte bestätigen Sie zuerst Ihre E-Mail Adresse");
			else console.log(err);
		})
	}

	const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      doLogin();
    }
  }

	return (
		<div id="login" style={{marginTop:"50px"}}>
			<p style={err == null ? {display: 'none'} : {color:"red"}}>{err}</p>
			<p style={info == null ? {display: 'none'} : {color:"black"}}>{info}</p>
			<MyInput placeholder="E-Mail oder Benutzername" style={{width: "175px", margin: "10px"}} type="text" value={name} set={setName}/>
			<MyInput onKeyDown={handleKeyDown} placeholder="Passwort" style={{width: "175px", margin: "10px"}} type="password" value={pw} set={setPw}/>
			<p>Für die Dauer des logins wird ein Cookie gesetzt. Mit dem klick auf login erklären Sie sich damit einverstanden</p>
			<button className="btn" style={{width: "175px", margin: "10px", textAlign:"center", marginTop:"20px"}} type="button" onClick={doLogin}>Login</button>
			<button className="btn" disabled={name.length==0} style={{width: "175px", margin: "10px", textAlign:"center", marginTop:"20px"}} type="button" onClick={reset}>Passwort vergessen</button>
			<Link to="/register"  style={{textAlign:"center", margin:"10px"}}>Registrieren</Link>
			<HowTo/>
		</div>
	)


}
// <input style={{width: "175px", margin: "10px"}} type="password" name="pw" />
