import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export default function Impressum() {
	// todo
	return (
		<div id="impressum" style={{textAlign:"left", display:"block", margin:"20px"}}>
			<h1>Impressum</h1>
			<h2>Angaben gemäß § 5 TMG</h2>
			<p>Jens Stuber<br/>Trollingerweg 12<br/>70734 Fellbach</p>
			<h2>Kontakt</h2>
			<p>Telefon: +49 (0) 1718479062<br/>Email: ginso89@googlemail.com</p>
			<h3>Haftung für Inhalte</h3>
			<p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
			<h3>Urheberrecht</h3>
		<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
		</div>
	)
}
