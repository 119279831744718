import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {MyInput, MySelect, MyTextArea, MyTr, query, queryBGG, formatRange, createOptions} from '../utils/utils';

export default function Search(props) {
	const [name, setName] = useState("")
	const [results, setResults] = useState([])
	const [filter, setFilter] = useState(true)
	const [mode, setMode] = useState(0)
	const [page, setPage] = useState(0)
	const [searching, setSearching] = useState(false)

	const loadImages = (res) => {
		console.log("loadImages")
		let arr = res ?? [...results]
		if(arr.length == 0) return
		if(arr[page*20].img) return
		queryBGG('thing', {id:arr.slice(page*20,page*20+20).map(r => r.id).join(",")}, data => {
			console.log(data)
			let map = {}
			Array.from(data.getElementsByTagName('item')).forEach(e => {
				let e2 = e.getElementsByTagName('thumbnail')[0]
				map[e.id] = e2 ? e2.innerHTML : ''
			});
			for(let i = page*20; i < page*20+20; i++) {
				if(i == arr.length) break
				arr[i] = {...arr[i], img: map[arr[i].id]}
			}
			setResults(arr)
		})
	}

	useEffect(loadImages, [page])



	const go = () => {
		setSearching(true)
		let f = arr => {
			queryBGG('search', {query:name, type:'boardgame'},data => {
				let results = Array.from(data.getElementsByTagName('item'));
				if(arr) results = results.filter(el => !arr.includes(parseInt(el.id)))
				results = results.map(e => {
					let id = parseInt(e.id)
					let e2 = e.getElementsByTagName('name')[0]
					let titel = e2 ? e2.attributes.value.value : '???'
					e2 = e.getElementsByTagName('yearpublished')[0]
					let year = e2 ? e2.attributes.value.value : null
					return {id, titel, year}
				})
				queryBGG('search', {query:name, type:'boardgameexpansion'}, data2 => {
					let ids = Array.from(data2.getElementsByTagName('item')).map(e => parseInt(e.id))
					results = results.filter(r => !ids.includes(r.id))
					setResults(results)
					console.log(results)
					if(page == 0) loadImages([...results])
					else setPage(0)
					setSearching(false)
				})
			})
		}
		if(filter) {
			query('getRatings', {}, data => f(data.map(d => d.bgg)), () => {})
		} else {
			f(null)
		}
	}
	const go2 = () => {
		setSearching(true)
		let f = arr => {
			queryBGG('collection', {username:name, subtype:'boardgame'},data => {
				if(data.getElementsByTagName('message').length > 0) {
					setTimeout(go2,1000)
					return
				}
				let results = Array.from(data.getElementsByTagName('item'));
				if(arr) results = results.filter(el => !arr.includes(parseInt(el.id)))
				results = results.map(e => {
					let id = parseInt(e.attributes.objectid.value)
					let e2 = e.getElementsByTagName('name')[0]
					let titel = e2 ? e2.innerHTML : '???'
					e2 = e.getElementsByTagName('yearpublished')[0]
					let year = e2 ? e2.innerHTML : null
					return {id, titel, year}
				})
				go3(results)
			})
		}
		if(filter) {
			query('getRatings', {}, data => f(data.map(d => d.bgg)), () => {})
		} else {
			f(null)
		}
	}

	const go3 = results => {
		queryBGG('collection', {username:name, subtype:'boardgameexpansion'}, data2 => {
			if(data2.getElementsByTagName('message').length > 0) {
				setTimeout(() => go3(results), 1000)
				return
			}
			let ids = Array.from(data2.getElementsByTagName('item')).map(e => parseInt(e.attributes.objectid.value))
			results = results.filter(r => !ids.includes(r.id))
			setResults(results)
			console.log(results)
			if(page == 0) loadImages([...results])
			else setPage(0)
			setSearching(false)
		})
	}
	let pages = []
	for(let i = 0; i < results.length/20; i++) pages.push(i+1)
	let start = page*20
	let end = Math.min(results.length, (page+1)*20)

	const changeRating = (idx, rating) => {
		let bgg = results[idx].id
		let name = results[idx].titel
		query('setRating', {bgg, rating,name}, () => {
			let copy = [...results]
			copy[idx] = {...copy[idx], rating}
			setResults(copy)
		})
	}
	const arr = [0,1,2,3,4,5,6,7,8,9]

	return (<div className="search">
		<div className="flexContainer stretch" style={{alignItems:"stretch !important", marginBottom:"5px"}}>
			<div className="container" style={{width:'400px'}}>
				<h2>
					<span>Suche</span>
				</h2>
				<div className="filler">
					<p>You can search for games by name, or load the collection of a user on boardgamegeek by searching for their (exact) user name</p>
					<table style={{whiteSpace:"nowrap"}}><tbody>
						<tr>
							<td>
								<MySelect value={mode} set={setMode}>
									<option value={0}>Game name</option>
									<option value={1}>User name</option>
								</MySelect>
							</td>
							<td><MyInput type="text" value={name} set={setName}/></td>
						</tr>
						<tr><td colSpan="2" style={{textAlign:'center'}}><MyInput type="checkbox" checked={filter} set={setFilter}/> only unrated games</td></tr>

					</tbody></table>
				</div>
			</div>
		</div>
		<button className="btn" style={{borderRadius:"10px", padding:"5px", display: "flex", alignItems:"center", justifyContent:"center", fontSize:"26px"}} onClick={mode == 0 ? go : go2} disabled={name.length < 3}>
			Suchen
			<img src="img/search.png" style={{height:"30px", marginLeft:"10px"}}/>
		</button>
		<div>
			<p style={{textAlign:'center'}}>Results {start+1} - {end} of {results.length}</p>
			{pages.length > 1 &&
				<p style={{display:'flex', justifyContent:'center', gap:'20px'}}>
					{
						pages.map((p,i) => <button key={i} className="btn" onClick={() => setPage(i)}>{p}</button>)
					}
				</p>
			}
			{searching ? 'searching' : <div id="searchResults">
				{
					results.slice(start, end).map((game,i) => (<div className="container" style={{width:'min(700px, 80vw)'}}>
						<h2 style={{height:'10px'}}></h2>
						<div className="filler" style={{display:'flex', justifyContent:'space-between'}}>
							{game.img && <img src={game.img} style={{height:'100px'}}/>}
							<div style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly', width:'min(450px, 80vw - 250px)'}}>
								<div>Name: {game.titel}</div>
								{game.year && <div>Year: {game.year}</div>}
								<div class="rating">
								{arr.map(j => (<div key={j} className="star" onClick={() => changeRating(i+page*20, j+1)}>
								{j < game.rating && game.rating ? '★' : '☆'}
								</div>))}
								</div>
							</div>
						</div>
					</div>))
				}
			</div>}
		</div>

	</div>)
}
