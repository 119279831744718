import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import store from './store';
import { Provider } from 'react-redux';
import {store, persistor} from './utils/Redux'
import {HashRouter as Router} from "react-router-dom";
import {createRoot} from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
		<Provider store={store}>
			<Router>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Router>
		</Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
