import React from 'react';
//import Tooltip from '../utils/Tooltip';

export const MyInput = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if(p.original != (p.value ?? "")) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return <input {...p} onChange={e => set(e.target.type == "checkbox" ? (e.target.checked ? 1 : 0) : e.target.value, props.idx)}/>
}

export const MySelect = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	delete p.children;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if(p.original != p.value) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return <select {...p} onChange={e => set(e.target.value, props.idx)}>{props.children}</select>
}

export const MyTextArea = props => {
	const p = {...props};
	const set = props.set;
	delete p.set;
	if(p.original !== undefined) {
		var classList = (p.className ?? "").split(" ");
		if((p.original ?? "") != (p.value ?? "")) {
			classList.push("changed");
		}
		p.className = classList.join(" ");
	}
	return <textarea {...p} onChange={e => set(e.target.value, props.idx)}/>
}


export const MyTr = props => {
	if(props.colspan == 2) {
		return (
			<tr >
				<td colSpan="2">
					{props.lbl}
					{props.children}
				</td>
			</tr>
		)
	}
	return (
		<tr >
			<td>
				{props.lbl}
			</td>
			<td>{props.children}</td>
		</tr>
	)
}
// unter {props.lbl}
//{props.tooltip && <Tooltip dark={true} styles={{width:"15px", height:"15px", marginLeft:"5px"}} text={props.tooltip}/>}

export const query = (f, d, callback, errorCB=null) => {
	var data = {...d}
	// var data = {...d, devGinso:1}
	//dev
	var url = serverUrl + '/sql.php?f=' + f;
	for(var key in data) url += "&" + key + "=" + encodeURIComponent(data[key]);
	console.log(url)
	fetch(url)
		.then(response => response.json())
		.then(json => {
			if(json.success) callback(json.data);
			else {
				console.log(json.data)
				if(errorCB) errorCB(json.data);
			}
		})
}


export const serverUrl = 'https://ginso.lima-city.de';

export const queryBGG = (f, d, callback) => {
	let url = "https://boardgamegeek.com/xmlapi2/" + f + "?"
	for(let key in d) url += key + "=" + d[key] + "&"
	console.log(url)
	fetch(url.substring(0,url.length-1))
		.then(r => r.text())
		.then(str => new window.DOMParser().parseFromString(str, "text/xml"))
		.then(callback)
}

export const formatRange = (min, max, suffix="") => {
	var noMin = (min ?? "").length == 0;
	var noMax = (max ?? "").length == 0;
	if(noMin && noMax) return null;
	if(noMin) return "max. " + max + suffix;
	if(noMax) return "min. " + min + suffix;
	return min + " - " + max + suffix;
}

export const createOptions = (values, keys=null) => {
	return values.map((value, i) => {
		var key = keys == null ? i : keys[i];
		return <option key={key} value={key}>{value}</option>
	});

}

export const formatDate = (s, time=false) => {
	var d = new Date(s);
	var options = {year: '2-digit', month: '2-digit', day: '2-digit' };
	if(time) options = {...options, hour: '2-digit', minute:'2-digit'}
	return d.toLocaleString('de-DE', options);
}
