import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

export default function HowTo() {
	return (<div>
		Hello,<br/>
		The purpose of this site is to recommend board  games to you, based on your rating of other board games and the ratungs of other users.<br/>
		This page is still quite new and is still in the stage of collecting data to make reliable predictions of what users might like.<br/>
		So, go ahead register, rate the games you know, and you will get notified once the page is ready to make predictions.<br/>
	</div>)
}
